import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import Header from "./more/Header";
import HeroSection from "./components/HeroSection";
import DonationForm from "./components/DonationForm";
import Footer from "./more/Footer";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./donation.css";

function Donation() {
  return (
    <div className="fontQuicksand" style={{ backgroundColor: "#F9F7F6" }}>
      <section
        className="w-100"
        style={{ position: "fixed", zIndex: "1000", backgroundColor: "#fff" }}
      >
        <Header />
      </section>
      <div style={{ height: "5rem" }}></div>
      <HeroSection />
      <section className="containerD h-100">
        <DonationForm />
        <img
          src={require("src/assets/donation/footerImage.png").default}
          alt=""
          className="w-100"
        />
      </section>
      <Footer />
    </div>
  );
}

export default Donation;
